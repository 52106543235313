import React, { FC } from 'react'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import { Trademark } from './types/Trademark'
import FooterTrademark from './FooterTrademark'

const trademarks: Trademark[] = [
    {
        title: 'Starzz',
        iconUrl: 'https://reshape.sport1.de/c/t/c6e53a46-0450-4077-ac78-07bfab92863c/0x35',
        targetUrl: 'https://www.starzz.eu/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Car4Sports',
        iconUrl: 'https://reshape.sport1.de/c/t/bbc42676-b83b-4aa6-a7d5-daff757e28b2/0x35',
        targetUrl: 'https://car4sports.de/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Deutsche Streaming Allianz',
        iconUrl: 'https://reshape.sport1.de/c/t/2f1e49d0-c6b8-47fb-90c3-d7d3d1dc3d05/0x35',
        targetUrl: 'https://deutsche-streaming-allianz.com/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Footbao',
        iconUrl: 'https://reshape.sport1.de/c/t/4aee5313-0336-4d61-ac60-7be207cc470a/0x35',
        targetUrl:
            'https://play.google.com/store/apps/details?id=world.footbao.footbao&gl=DE&utm_source=sport1&utm_medium=banner&utm_campaign=kam_sport1_juni_2023&pli=1',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Clanq',
        iconUrl: 'https://reshape.sport1.de/c/t/c985945b-5464-495c-b90e-8aaa47091150/0x35',
        targetUrl: 'https://www.clanq.de/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Racemates',
        iconUrl: 'https://reshape.sport1.de/c/t/b1790037-f61b-4ec1-a80a-9b812e55eda8/0x35',
        targetUrl: 'https://racemates-nft.com/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'TigerSpin',
        iconUrl: 'https://reshape.sport1.de/c/t/5ededbec-ba6b-4999-b0c5-35250e551a3d/0x35',
        targetUrl: 'https://www.tigerspin.de/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
]

const FooterTrademarkList: FC = () => {
    return (
        <FlexingContainer className="bg-white justify-between gap-[20px]" horizontal>
            {trademarks.map((trademark, index) => (
                <FooterTrademark trademark={trademark} key={`${trademark.title}-${index}`} />
            ))}
        </FlexingContainer>
    )
}

export default FooterTrademarkList
