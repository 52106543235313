import { FooterSeoNavigationColumn } from './types/FooterSeoNavigationItem'
import Config from '@/utils/Config'

const navigationItemsCol1: FooterSeoNavigationColumn = {
    SPORTARTEN: [
        {
            title: 'Fußball',
            targetUrl: `${Config.HOST}/channel/fussball`,
            target: '_self',
        },
        {
            title: 'Motorsport',
            targetUrl: `${Config.HOST}/channel/motorsport`,
            target: '_self',
        },
        {
            title: 'Darts',
            targetUrl: `${Config.HOST}/channel/darts-sport`,
            target: '_self',
        },
        {
            title: 'US-Sport',
            targetUrl: `${Config.HOST}/channel/us-sport`,
            target: '_self',
        },
        {
            title: 'Olympia',
            targetUrl: `${Config.HOST}/channel/olympia`,
            target: '_self',
        },
    ],
}

const navigationItemsCol2: FooterSeoNavigationColumn = {
    'SOCIAL MEDIA': [
        {
            title: 'Facebook',
            targetUrl: 'https://www.facebook.com/SPORT1News',
            target: '_blank',
            rel: 'noreferrer noopener nofollow',
        },
        {
            title: 'Instagram',
            targetUrl: 'https://www.instagram.com/sport1news',
            target: '_blank',
            rel: 'noreferrer noopener nofollow',
        },
        {
            title: 'X',
            targetUrl: 'https://twitter.com/SPORT1',
            target: '_blank',
            rel: 'noreferrer noopener nofollow',
        },
        {
            title: 'TikTok',
            targetUrl: 'https://www.tiktok.com/@sport1news',
            target: '_blank',
            rel: 'noreferrer noopener',
        },
        {
            title: 'YouTube',
            targetUrl: 'https://www.youtube.com/user/SPORT1',
            target: '_blank',
            rel: 'noreferrer noopener nofollow',
        },
    ],
}

const navigationItemsCol3: FooterSeoNavigationColumn = {
    VIDEO: [
        {
            title: 'Mediathek',
            targetUrl: `${Config.HOST}/tv-video`,
            target: '_self',
        },
        {
            title: 'Livestream',
            targetUrl: `${Config.HOST_TV}/live/sport1`,
            target: '_self',
        },
        {
            title: 'Podcasts',
            targetUrl: 'https://www.sport1.de/channel/podcasts',
            target: '_blank',
            rel: 'noreferrer noopener',
        },
        {
            title: 'Doppelpass',
            targetUrl: `${Config.HOST}/tv-video/playlisten/doppelpass`,
            target: '_self',
        },
    ],
}

const navigationItemsCol4: FooterSeoNavigationColumn = {
    SERVICE: [
        {
            title: 'News-App',
            targetUrl: `${Config.HOST}/news/apps/sport1-app`,
            target: '_self',
        },
        {
            title: 'Liveticker',
            targetUrl: `${Config.HOST}/live`,
            target: '_self',
        },
        {
            title: 'eSPORTS1',
            targetUrl: 'https://www.esports1.de',
            target: '_blank',
            rel: 'noreferrer noopener',
        },
        {
            title: 'SPORT1+',
            targetUrl: `${Config.HOST_TV}/live/sport1plus`,
            target: '_self',
        },
        {
            title: 'Sport1 Deals',
            targetUrl: `${Config.HOST}/channel/angebote`,
            target: '_self',
        },
    ],
}

const navigationItemsCol5: FooterSeoNavigationColumn = {
    MEHR: [
        {
            title: 'TV-Programm',
            targetUrl: `${Config.HOST_TV}/programm`,
            target: '_self',
        },
        {
            title: 'Sportquiz',
            targetUrl: `${Config.HOST}/news/tv-video/sport1/sendungen/sportquiz`,
            target: '_self',
        },
        {
            title: 'Tippspiel',
            targetUrl: 'https://tippspiel.sport1.de/',
            target: '_blank',
            rel: 'noreferrer noopener',
        },
        {
            title: 'Sportwetten',
            targetUrl: `${Config.HOST}/channel/sportwetten`,
            target: '_self',
        },
        {
            title: 'SPORT1 Akademie',
            targetUrl: `https://akademie.sport1.de/n`,
            target: '_self',
            rel: 'nofollow',
        },
    ],
}
const navigationItemsCol6: FooterSeoNavigationColumn = {
    'ÜBER UNS': [
        {
            title: 'Unternehmen',
            targetUrl: `${Config.HOST}/unternehmen`,
            target: '_self',
            rel: 'nofollow',
        },
        {
            title: 'Karriere',
            targetUrl:
                'https://karriere.sport1-medien.de/?utm_source=sport1&utm_medium=website&utm_campaign=sport1',
            target: 'blank',
            rel: 'nofollow',
        },
        {
            title: 'Kontakt',
            targetUrl: `${Config.HOST}/unternehmen/kontakt`,
            target: '_self',
            rel: 'nofollow',
        },
        {
            title: 'Presse',
            targetUrl: `${Config.HOST}/unternehmen/presse`,
            target: '_self',
            rel: 'nofollow',
        },
        {
            title: 'SPORT1 Business',
            targetUrl: 'https://business.sport1.de/',
            target: '_blank',
            rel: 'nofollow',
        },
    ],
}

export const navigationItems: Array<FooterSeoNavigationColumn> = [
    navigationItemsCol1,
    navigationItemsCol2,
    navigationItemsCol3,
    navigationItemsCol4,
    navigationItemsCol5,
    navigationItemsCol6,
]
